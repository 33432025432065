import React, { useState } from 'react';
import "./GlobalPopup.css";

const GlobalPopup = ({ show }) => {
    const [showModel, setShowModel] = useState(show);

    const showHideClassName = showModel ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <span className='closeButton' onClick={() => setShowModel(false)}>X</span>
                <div className='content'>
                    Hi, Hello,  Hello world!, Hello,  Hello world!, Hello,  Hello world!, Hello,  Hello world!
                    {/* {children} */}
                    <br />
                    <br />
                    <button type="button" onClick={() => setShowModel(false)} style={{ float: "right" }}>
                        Ok
                    </button>
                </div>
            </section>
        </div>
    );
}

export default GlobalPopup;
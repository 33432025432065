import React from 'react';
import ReactDOM from 'react-dom';
import GlobalPopup from './components/GlobalPopup/GlobalPopup';
import Card from './components/UI/Card';
// import './index.css';
// import BlogCategory from './components/BlogCategory/BlogCategory';
// import Blog from './components/Blog/Blog';
// import App from './App';
import reportWebVitals from './reportWebVitals';
//Bootsrap Includes
// import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <GlobalPopup show={true} />
    
    <Card>
      <h1>Hello world!</h1>
    </Card>
    <h1 className="text-3xl font-thin underline">
      Hello world!
    </h1>
  </React.StrictMode>
  ,
  document.getElementById('root')
  );













  // {/* <StyledEngineProvider injectFirst>
  // <BrowserRouter>
  // <CssBaseline/>
  // <MatxTheme>
  //   <MatxSidenav><p>Hello</p> </MatxSidenav>
  //   <MatxDivider text={"Hello"} />
  //   <Blog></Blog>
  // </MatxTheme>
  // </BrowserRouter>
  // </StyledEngineProvider> */}
  // {/* <Navbar sticky="top" bg='dark' variant="dark">
  //   <Container fluid>
  //     <Navbar.Brand>Techsaga</Navbar.Brand>
  //   </Container>
  // </Navbar>
  // <Container>
  //   <Blog></Blog> */}
  //   {/* <BlogCategory/> */}
  // {/* </Container> */}
  // {/* <App /> */}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
